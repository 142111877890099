import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Container, Grid, Icon, Message, Modal, Table} from "semantic-ui-react";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

export default function TimeMachineRundownCMSImageUploadModal(props) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onClose = () => {
        setSelectedImage(null);
        props.onCloseModal();
    }

    const saveClicked = () => {
        console.log(`TimeMachineCMSImageUploadModal.saveClicked for scope ${props.scope}, scope id ${props.scopeId}, and image id ${props.imageId}, attempting to save selected image: `, selectedImage);
        const fileFormat = selectedImage[0].fileExtension;
        let reader = new FileReader();
        reader.readAsDataURL(selectedImage[0].source);
        reader.onload = function () {
            setUploadingImage(true);
            props.TimeMachineDataProvider.cmsUploadImage(props.imageId, props.scope, props.scopeId, fileFormat, reader.result).then(response => {
                console.log(response);
                if (!response.hasOwnProperty("succeeded") || !response.succeeded) {
                    console.error(response);
                }
                if (response.url) {
                    console.log("updating image to use url: ", response.url);
                    props.updateImage(props.scope.toLowerCase(), props.scopeId, props.imageId, response.url, props.language);
                }

                setSuccessMessage("The selected image has been successfully uploaded.");
            }).catch(error => {
                setErrorMessage("There was an error saving the selected image.");
                console.error(error);
            }).finally(() => {
                setUploadingImage(false);
                onClose();
            });
        }
    }

    return (
        <Modal
            size="large"
            open={props.isModalOpen}
            onOpen={props.onOpenModal}
            onClose={() => onClose()}
            trigger={props.trigger}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            closeIcon
            style={{position: "relative"}}
        >
            <Modal.Header>Upload Image</Modal.Header>
            <Modal.Content>
                {
                    uploadingImage ?
                        <Message icon color="blue">
                            <Icon name="spinner" loading />
                            <Message.Content>Uploading image...</Message.Content>
                        </Message> :
                    successMessage ?
                        <Message icon success>
                            <Icon name="check" />
                            <Message.Content>{successMessage}</Message.Content>
                        </Message> :
                    errorMessage ?
                        <Message icon error>
                            <Icon name="warning" />
                            <Message.Content>{errorMessage}</Message.Content>
                        </Message> : ""
                }
                <Container fluid>
                    <Grid>
                        <Grid.Row>
                            <Table>
                                <Table.Header>
                                    {
                                        Object.keys(props.imageOverride).filter(key => key !== "url").map(
                                            key =>  (
                                                <Table.Row>
                                                    <Table.Cell><b>{`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</b></Table.Cell>
                                                    <Table.Cell>{props.imageOverride[key]}</Table.Cell>
                                                </Table.Row>
                                            )
                                        )
                                    }
                                </Table.Header>
                            </Table>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="App">
                                    <FilePond
                                        files={selectedImage}
                                        onupdatefiles={setSelectedImage}
                                        allowMultiple={false}
                                        name="files"
                                        labelIdle="Drag & Drop your image or <span class='filepond--label-action'>Browse</span>"
                                        acceptedFileTypes={["image/*"]}
                                        credits={false}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                <Container fluid>
                    <Button disabled={!selectedImage} floated="right" positive onClick={saveClicked}>Save</Button>
                    <Button onClick={onClose} floated="right" color="black">{successMessage ? "Exit" : "Cancel"}</Button>
                </Container>
            </Modal.Content>
        </Modal>
    );
};

TimeMachineRundownCMSImageUploadModal.propTypes = {
    scope: PropTypes.string.isRequired,
    scopeId: PropTypes.string.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    trigger: PropTypes.object,
    imageOverride: PropTypes.object.isRequired,
    TimeMachineDataProvider: PropTypes.object.isRequired,
}