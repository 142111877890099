import React, {FC, ReactElement} from "react";
import {Button, Modal} from "semantic-ui-react";

type TimeMachineCMSHelpModalProps = {
    isModalOpen: boolean,
    onOpenModal: Function,
    onCloseModal: Function
};

const TimeMachineRundownCMSImageManagerHelpModal: FC<TimeMachineCMSHelpModalProps> = (props): ReactElement => {
    return (
        <Modal
            open={props.isModalOpen}
            onClose={() => props.onCloseModal()}
            onOpen={() => props.onOpenModal()}
            size="large"
            closeIcon
        >
            <Modal.Header>Instructions</Modal.Header>
            <Modal.Content>
                <p>
                    The Time Machine CMS Image Manager is for setting image overrides for a show that may affect what data is sent to InFlow.
                    <br /><br />
                    The table at the top of the Image Manager shows the various IDs that a show has; these are the scopes for which images can be overridden.
                    <br /><br />
                    Linear shows will generally have a Show ID, Title ID, Season ID, Series ID, and Franchise ID. Some IDs may be missing; this is normal.
                    <br /><br />
                    The most granular level that image overrides can be applied over is the Show scope, which may also be referred to as an airing or a program.
                    Any overrides applied here will not affect other shows.
                    <br /><br />
                    Overrides applied to other scopes, however, can affect other airings. Franchise is the most broad scope for which images can be applied in the CMS.
                    <br /><br />
                    <hr />
                    Below the table, there is a series of tabs, ordered from the broadest to the most granular scope, for which image overrides can be uploaded.
                    <br /><br />
                    Each tab has three columns. In the leftmost column are the image overrides uploaded for the tab's scope.
                    <br /><br />
                    The middle tab has details for the image--the current URL, if there is an image, as well as the image ID, kind, width, height, and aspect ratio.
                    Additionally, it allows you to either replace or delete the current image overrides.
                    <br /><br />
                    The tab on the right shows the images for the selected show after all overrides have been applied.
                    The blue label in the top left indicates which image override, if any, has been applied.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={()=> props.onCloseModal()}>Okay</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default TimeMachineRundownCMSImageManagerHelpModal;
