 import React, {Component, createRef} from "react";
import {Accordion, Checkbox, Container, Grid, Icon, Image, Item, Menu, Sticky, Table} from "semantic-ui-react";
import TimeMachineRundownCMSImageEditorItem from "./TimeMachineRundownCMSImageEditorItem";

export default class TimeMachineRundownCMSImageManagerDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabs: {},
            selectedMenuIndex: 0,
            selectedScope: "",
            selectedScopeKey: "",
            activePanels: [],
            imagesWithOverridesApplied: {},
            selectedLanguageTab: "en-US"
        };
    }

    contextRef = createRef();

    componentDidMount() {
        const activePanels = [];
        for (let i = 0; i < Object.keys(this.props.imageSpecs).length; i++) {
            activePanels.push(true);
        }

        let selectedMenuIndex = 0;
        let selectedScope = "franchise";
        let selectedScopeKey = "franchise";
        if (this.props.franchiseId.length < 1) {
            if (this.props.seriesId.length > 0) {
                selectedMenuIndex = 1;
                selectedScope = "series";
                selectedScopeKey = "series";
            } else if (this.props.seasonId.length > 0) {
                selectedMenuIndex = 2;
                selectedScope = "season";
                selectedScopeKey = "season";
            } else if (this.props.propEp.length > 0) {
                selectedMenuIndex = 3;
                selectedScope = "title by prop ep";
                selectedScopeKey = "prop_ep";
            } else if (this.props.titleId.length > 0) {
                selectedMenuIndex = 4;
                selectedScope = "title by title id";
                selectedScopeKey = "title";
            } else if (this.props.externalId.length > 0) {
                selectedMenuIndex = 5;
                selectedScope = "show by external id";
                selectedScopeKey = "external id";
            } else if (this.props.showId.length > 0) {
                selectedMenuIndex = 6;
                selectedScope = "show by show id";
                selectedScopeKey = "show";
            }
        }

        this.setState({activePanels, selectedMenuIndex, selectedScope, selectedScopeKey});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const showIdUpdated = this.props.showId !== prevProps.showId;
        const externalIdUpdated = this.props.externalId !== prevProps.externalId;
        const titleIdUpdated = this.props.titleId !== prevProps.titleId;
        const propEpUpdated = this.props.propEp !== prevProps.propEp;
        const seasonIdUpdated = this.props.seasonId !== prevProps.seasonId;
        const seriesIdUpdated = this.props.seriesId !== prevProps.seriesId;
        const franchiseIdUpdated = this.props.franchiseId !== prevProps.franchiseId;
        if (showIdUpdated || externalIdUpdated || titleIdUpdated || propEpUpdated || seasonIdUpdated || seriesIdUpdated || franchiseIdUpdated) {
            let selectedMenuIndex = 0;
            if (this.props.franchiseId.length < 1) {
                if (this.props.seriesId.length > 0) {
                    selectedMenuIndex = 1;
                } else if (this.props.seasonId.length > 0) {
                    selectedMenuIndex = 2;
                } else if (this.props.propEp.length > 0) {
                    selectedMenuIndex = 3;
                } else if (this.props.titleId.length > 0) {
                    selectedMenuIndex = 4;
                } else if (this.props.externalId.length > 0) {
                    selectedMenuIndex = 5;
                } else if (this.props.showId.length > 0) {
                    selectedMenuIndex = 6;
                }
            }
            this.setState({selectedMenuIndex});
        }

        if (this.state.selectedMenuIndex !== prevState.selectedMenuIndex) {
            this.setState({
                selectedScope: ["franchise", "series", "season", "title by prop ep", "title by title id", "show by external id", "show by show id"][this.state.selectedMenuIndex],
                selectedScopeKey: ["franchise", "series", "season", "prop_ep", "title", "external_id", "show"][this.state.selectedMenuIndex],
            });
        }

        if (JSON.stringify(prevProps.imageSpecs) !== JSON.stringify(this.props.imageSpecs)) {
            const activePanels = [];
            for (let i = 0; i < Object.keys(this.props.imageSpecs).length; i++) {
                activePanels.push(true);
            }
            this.setState({activePanels});

            // this.props.imageOverrides?.[this.state.selectedScopeKey]?.override_source
            if (JSON.stringify(prevProps.imageOverrides) !== JSON.stringify(this.props.imageOverrides)) {
                console.log("TimeMachineRundownCMSImageManagerDetailView.componentDidUpdate: updated override source? ", this.props.imageOverrides[this.state.selectedScopeKey].override_source);
            }
        }

        if (JSON.stringify(prevProps.images) !== JSON.stringify(this.props.images) || JSON.stringify(prevProps.imageOverrides) !== JSON.stringify(this.props.imageOverrides)) {
            const imagesWithOverridesApplied = Object.assign({}, this.props.images.i18n[this.state.selectedLanguageTab]);
            if (this.props.imageOverrides.hasOwnProperty("franchise")) {
                for (const [imageId, override] of Object.entries(this.props.imageOverrides.franchise.i18n[this.state.selectedLanguageTab])) {
                    if (override.url && (this.props.imageOverrides.franchise.override_source === true || ["Default"].includes(this.props.images.i18n[this.state.selectedLanguageTab][imageId].scope))) {
                        imagesWithOverridesApplied[imageId] = Object.assign(imagesWithOverridesApplied[imageId], override, {scope: "Franchise"});
                    }
                }
            }
            if (this.props.imageOverrides.hasOwnProperty("series")) {
                for (const [imageId, override] of Object.entries(this.props.imageOverrides.series.i18n[this.state.selectedLanguageTab])) {
                    if (override.url && (this.props.imageOverrides.series.override_source === true || ["Default", "Franchise"].includes(this.props.images.i18n[this.state.selectedLanguageTab][imageId].scope))) {
                        imagesWithOverridesApplied[imageId] = Object.assign(imagesWithOverridesApplied[imageId], override, {scope: "Series"});
                    }
                }
            }

            if (this.props.imageOverrides.hasOwnProperty("season")) {
                for (const [imageId, override] of Object.entries(this.props.imageOverrides.season.i18n[this.state.selectedLanguageTab])) {
                    if (override.url && (this.props.imageOverrides.season.override_source === true || ["Default", "Franchise", "Series"].includes(this.props.images.i18n[this.state.selectedLanguageTab][imageId].scope))) {
                        imagesWithOverridesApplied[imageId] = Object.assign({}, override, {scope: "Season"});
                    }
                }
            }

            if (this.props.imageOverrides.hasOwnProperty("prop_ep")) {
                for (const [imageId, override] of Object.entries(this.props.imageOverrides.title.i18n[this.state.selectedLanguageTab])) {
                    if (override.url && (this.props.imageOverrides.prop_ep.override_source === true || ["Default", "Franchise", "Series", "Season"].includes(this.props.images.i18n[this.state.selectedLanguageTab][imageId].scope))) {
                        imagesWithOverridesApplied[imageId] = Object.assign(imagesWithOverridesApplied[imageId], override, {scope: "Title by Prop Ep"});
                    }
                }
            }

            if (this.props.imageOverrides.hasOwnProperty("title")) {
                for (const [imageId, override] of Object.entries(this.props.imageOverrides.title.i18n[this.state.selectedLanguageTab])) {
                    if (override.url && (this.props.imageOverrides.title.override_source === true || ["Default", "Franchise", "Series", "Season", "Title by Prop Ep"].includes(this.props.images.i18n[this.state.selectedLanguageTab][imageId].scope))) {
                        imagesWithOverridesApplied[imageId] = Object.assign(imagesWithOverridesApplied[imageId], override, {scope: "Title by Title Id"});
                    }
                }
            }

            if (this.props.imageOverrides.hasOwnProperty("external_id")) {
                for (const [imageId, override] of Object.entries(this.props.imageOverrides.show.i18n[this.state.selectedLanguageTab])) {
                    if (override.url && (this.props.imageOverrides.external_id.override_source === true || ["Default", "Franchise", "Series", "Season", "Title", "Title by Prop Ep"].includes(this.props.images.i18n[this.state.selectedLanguageTab][imageId].scope))) {
                        imagesWithOverridesApplied[imageId] = Object.assign(imagesWithOverridesApplied[imageId], override, {scope: "Show by External Id"});
                    }
                }
            }

            if (this.props.imageOverrides.hasOwnProperty("show")) {
                for (const [imageId, override] of Object.entries(this.props.imageOverrides.show.i18n[this.state.selectedLanguageTab])) {
                    if (override.url && (this.props.imageOverrides.show.override_source === true || ["Default", "Franchise", "Series", "Season", "Title", "Title by Prop Ep", "Show by External Id"].includes(this.props.images.i18n[this.state.selectedLanguageTab][imageId].scope))) {
                        imagesWithOverridesApplied[imageId] = Object.assign(imagesWithOverridesApplied[imageId], override, {scope: "Show by Show Id"});
                    }
                }
            }

            this.setState({imagesWithOverridesApplied});
        }
    }

    getPanels = () => {
        const panels = [];

        for (let i = 0; i < this.state.activePanels.length; i++) {
            const imageId = Object.keys(this.props.imageSpecs)[i];
            console.log(`TimeMachineCMSImageManagerDetailView.getPanels: image ID: ${imageId}; image specs: `, this.props.imageSpecs, "images: ", this.props.images, "image overrides: ", this.props.imageOverrides);
            const imageMetadata = Object.assign({}, this.props.images.i18n[this.state.selectedLanguageTab][imageId]);
            const url = imageMetadata.url;
            const franchiseOverrides = this.props.imageOverrides.franchise.i18n[this.state.selectedLanguageTab];
            const seriesOverrides = this.props.imageOverrides.series.i18n[this.state.selectedLanguageTab];
            const seasonOverrides = this.props.imageOverrides.season.i18n[this.state.selectedLanguageTab];
            const propEpOverrides = this.props.imageOverrides.prop_ep.i18n[this.state.selectedLanguageTab];
            const titleOverrides = this.props.imageOverrides.title.i18n[this.state.selectedLanguageTab];
            const externalIdOverrides = this.props.imageOverrides.external_id.i18n[this.state.selectedLanguageTab];
            const showOverrides = this.props.imageOverrides.show.i18n[this.state.selectedLanguageTab];

            console.log("TimeMachineRundownCMSImageManagerDetailView.getPanels: franchise overrides: ", franchiseOverrides);

            panels.push(
                <Accordion.Panel
                    active={this.state.activePanels[i] === true}
                    onTitleClick={
                        () => {
                            const activePanels = this.state.activePanels.slice();
                            activePanels[i] = !activePanels[i];
                            this.setState({activePanels});
                        }
                    }
                    title={this.props.imageSpecs[imageId].name}
                    key={`${imageId}-${Object.keys(this.props.imageOverrides)[i]}`}
                    as={Container}
                    styled
                    style={{hidden: this.state.activePanels[i]}}
                    content={
                        <Accordion.Content active={this.state.activePanels[i] === true}>
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={12} verticalAlign="top">
                                            {
                                                this.props.franchiseId && this.state.selectedMenuIndex === 0 && franchiseOverrides.hasOwnProperty(imageId) &&
                                                <Item.Group>
                                                    <TimeMachineRundownCMSImageEditorItem
                                                        imageId={imageId}
                                                        imageOverride={franchiseOverrides[imageId]}
                                                        scope="Franchise"
                                                        scopeId={this.props.franchiseId}
                                                        TimeMachineDataProvider={this.props.TimeMachineDataProvider}
                                                        setUpdatedImages={this.props.setUpdatedImages}
                                                        updateImage={this.props.updateImage}
                                                        removeImageFromOverrides={this.props.removeImageFromOverrides}
                                                        deleteImage={this.props.deleteImage}
                                                        userCanEdit={[this.props.userCanEdit]}
                                                        allPermissions={this.props.allPermissions}
                                                        module={this.props.module}
                                                        instance={this.props.instance}
                                                        service={this.props.service}
                                                        user={this.props.user}
                                                        imageNotFoundURL={this.props.imageNotFoundURL}
                                                        language={this.state.selectedLanguageTab}
                                                    />
                                                </Item.Group>
                                            }
                                            {
                                                this.props.seriesId && this.state.selectedMenuIndex === 1 && seriesOverrides.hasOwnProperty(imageId) &&
                                                <Item.Group>
                                                    <TimeMachineRundownCMSImageEditorItem
                                                        imageId={imageId}
                                                        imageOverride={seriesOverrides[imageId]}
                                                        scope="Series"
                                                        scopeId={this.props.seriesId}
                                                        TimeMachineDataProvider={this.props.TimeMachineDataProvider}
                                                        setUpdatedImages={this.props.setUpdatedImages}
                                                        updateImage={this.props.updateImage}
                                                        removeImageFromOverrides={this.props.removeImageFromOverrides}
                                                        deleteImage={this.props.deleteImage}
                                                        userCanEdit={[this.props.userCanEdit]}
                                                        allPermissions={this.props.allPermissions}
                                                        module={this.props.module}
                                                        instance={this.props.instance}
                                                        service={this.props.service}
                                                        user={this.props.user}
                                                        imageNotFoundURL={this.props.imageNotFoundURL}
                                                        language={this.state.selectedLanguageTab}
                                                    />
                                                </Item.Group>
                                            }
                                            {
                                                this.props.seasonId && this.state.selectedMenuIndex === 2 && seasonOverrides.hasOwnProperty(imageId) &&
                                                <Item.Group>
                                                    <TimeMachineRundownCMSImageEditorItem
                                                        imageId={imageId}
                                                        imageOverride={seasonOverrides[imageId]}
                                                        scope="Season"
                                                        scopeId={this.props.seasonId}
                                                        TimeMachineDataProvider={this.props.TimeMachineDataProvider}
                                                        setUpdatedImages={this.props.setUpdatedImages}
                                                        updateImage={this.props.updateImage}
                                                        removeImageFromOverrides={this.props.removeImageFromOverrides}
                                                        deleteImage={this.props.deleteImage}
                                                        userCanEdit={[this.props.userCanEdit]}
                                                        allPermissions={this.props.allPermissions}
                                                        module={this.props.module}
                                                        instance={this.props.instance}
                                                        service={this.props.service}
                                                        user={this.props.user}
                                                        imageNotFoundURL={this.props.imageNotFoundURL}
                                                        language={this.state.selectedLanguageTab}
                                                    />
                                                </Item.Group>
                                            }
                                            {
                                                this.props.propEp && this.state.selectedMenuIndex === 3 && propEpOverrides.hasOwnProperty(imageId) &&
                                                <Item.Group>
                                                    <TimeMachineRundownCMSImageEditorItem
                                                        imageId={imageId}
                                                        imageOverride={propEpOverrides[imageId]}
                                                        scope="Title by Prop Ep"
                                                        scopeId={this.props.propEp}
                                                        TimeMachineDataProvider={this.props.TimeMachineDataProvider}
                                                        setUpdatedImages={this.props.setUpdatedImages}
                                                        updateImage={this.props.updateImage}
                                                        removeImageFromOverrides={this.props.removeImageFromOverrides}
                                                        deleteImage={this.props.deleteImage}
                                                        userCanEdit={[this.props.userCanEdit]}
                                                        allPermissions={this.props.allPermissions}
                                                        module={this.props.module}
                                                        instance={this.props.instance}
                                                        service={this.props.service}
                                                        user={this.props.user}
                                                        imageNotFoundURL={this.props.imageNotFoundURL}
                                                        language={this.state.selectedLanguageTab}
                                                    />
                                                </Item.Group>
                                            }
                                            {
                                                this.props.titleId && this.state.selectedMenuIndex === 4 && titleOverrides.hasOwnProperty(imageId) &&
                                                <Item.Group>
                                                    <TimeMachineRundownCMSImageEditorItem
                                                        imageId={imageId}
                                                        imageOverride={titleOverrides[imageId]}
                                                        scope="Title by Title Id"
                                                        scopeId={this.props.titleId}
                                                        TimeMachineDataProvider={this.props.TimeMachineDataProvider}
                                                        setUpdatedImages={this.props.setUpdatedImages}
                                                        updateImage={this.props.updateImage}
                                                        removeImageFromOverrides={this.props.removeImageFromOverrides}
                                                        deleteImage={this.props.deleteImage}
                                                        userCanEdit={[this.props.userCanEdit]}
                                                        allPermissions={this.props.allPermissions}
                                                        module={this.props.module}
                                                        instance={this.props.instance}
                                                        service={this.props.service}
                                                        user={this.props.user}
                                                        imageNotFoundURL={this.props.imageNotFoundURL}
                                                        language={this.state.selectedLanguageTab}
                                                    />
                                                </Item.Group>
                                            }
                                            {
                                                this.props.externalId && this.state.selectedMenuIndex === 5 && externalIdOverrides.hasOwnProperty(imageId) &&
                                                <Item.Group>
                                                    <TimeMachineRundownCMSImageEditorItem
                                                        imageId={imageId}
                                                        imageOverride={externalIdOverrides[imageId]}
                                                        overrideSource={externalIdOverrides.override_source || false}
                                                        toggleOverrideSource={this.props.toggleOverrideSource}
                                                        scope="Show by External Id"
                                                        scopeId={this.props.externalId}
                                                        TimeMachineDataProvider={this.props.TimeMachineDataProvider}
                                                        setUpdatedImages={this.props.setUpdatedImages}
                                                        updateImage={this.props.updateImage}
                                                        removeImageFromOverrides={this.props.removeImageFromOverrides}
                                                        deleteImage={this.props.deleteImage}
                                                        userCanEdit={[this.props.userCanEdit]}
                                                        allPermissions={this.props.allPermissions}
                                                        module={this.props.module}
                                                        instance={this.props.instance}
                                                        service={this.props.service}
                                                        user={this.props.user}
                                                        imageNotFoundURL={this.props.imageNotFoundURL}
                                                        language={this.state.selectedLanguageTab}
                                                    />
                                                </Item.Group>
                                            }
                                            {
                                                this.props.showId && this.state.selectedMenuIndex === 6 && showOverrides.hasOwnProperty(imageId) &&
                                                    <Item.Group>
                                                        <TimeMachineRundownCMSImageEditorItem
                                                            imageId={imageId}
                                                            imageOverride={showOverrides[imageId]}
                                                            overrideSource={showOverrides.override_source || false}
                                                            toggleOverrideSource={this.props.toggleOverrideSource}
                                                            scope="Show by Show Id"
                                                            scopeId={this.props.showId}
                                                            TimeMachineDataProvider={this.props.TimeMachineDataProvider}
                                                            setUpdatedImages={this.props.setUpdatedImages}
                                                            updateImage={this.props.updateImage}
                                                            removeImageFromOverrides={this.props.removeImageFromOverrides}
                                                            deleteImage={this.props.deleteImage}
                                                            userCanEdit={[this.props.userCanEdit]}
                                                            allPermissions={this.props.allPermissions}
                                                            module={this.props.module}
                                                            instance={this.props.instance}
                                                            service={this.props.service}
                                                            user={this.props.user}
                                                            imageNotFoundURL={this.props.imageNotFoundURL}
                                                            language={this.state.selectedLanguageTab}
                                                        />
                                                    </Item.Group>
                                            }
                                        </Table.Cell>
                                        <Table.Cell width={4} verticalAlign="top">
                                            <Image fluid size="medium" label={url ? {as: "a", color: "blue", content: this.props.images.i18n[this.state.selectedLanguageTab][imageId].scope, ribbon: true} : false} src={url ? url : this.props.imageNotFoundURL ? this.props.imageNotFoundURL : "https://akamai.warnermediacdn.com/inflow/lower/18c8d64c-9081-4a3d-b15a-a2cdcd6ef19e.jpeg"} centered />
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Accordion.Content>
                    }
                />
            );
        }
        return panels;
    }

    render() {
        return (
            <div ref={this.contextRef}>
                <Grid.Column width={16}>
                    <Sticky context={this.contextRef} offset={40}>
                        <Menu attached="top" tabular style={{backgroundColor: "#fff"}}>
                            <Menu.Item disabled><Icon name="language" size="big" /></Menu.Item>
                            {
                                this.props.languagesSupported.map(language =>
                                    <Menu.Item active={this.state.selectedLanguageTab === language} onClick={() => this.setState({selectedLanguageTab: language})}>{language}</Menu.Item>
                                )
                            }
                        </Menu>
                        <Menu attached="bottom" secondary pointing style={{backgroundColor: "#f5f5f5"}} >
                            <Menu.Item disabled><Icon name="list ol" size="large"/></Menu.Item>
                            {this.props.franchiseId && <Menu.Item active={this.state.selectedMenuIndex === 0} name="Franchise" onClick={() => this.setState({selectedMenuIndex: 0})}/>}
                            {this.props.seriesId && <Menu.Item active={this.state.selectedMenuIndex === 1} name="Series" onClick={() => this.setState({selectedMenuIndex: 1})}/>}
                            {this.props.seasonId && <Menu.Item active={this.state.selectedMenuIndex === 2} name="Season" onClick={() => this.setState({selectedMenuIndex: 2})} />}
                            {this.props.titleId && <Menu.Item active={this.state.selectedMenuIndex === 3} name="Title by Prop Ep" onClick={() => this.setState({selectedMenuIndex: 3})}/>}
                            {this.props.titleId && <Menu.Item active={this.state.selectedMenuIndex === 4} name="Title by Title Id" onClick={() => this.setState({selectedMenuIndex: 4})}/>}
                            {this.props.showId && <Menu.Item active={this.state.selectedMenuIndex === 5} name="Show by External Id" onClick={() => this.setState({selectedMenuIndex: 5})}/>}
                            {this.props.showId && <Menu.Item active={this.state.selectedMenuIndex === 6} name="Show by Show Id" onClick={() => this.setState({selectedMenuIndex: 6})}/>}
                        </Menu>
                    </Sticky>
                    <Table>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell colSpan="2">
                                    <Checkbox
                                        toggle
                                        label="Override Source Images (Applies to all languages for the given scope)"
                                        checked={this.props.imageOverrides?.[this.state.selectedScopeKey]?.override_source === true}
                                        // checked={this.props.imageOverrides.hasOwnProperty(this.state.selectedScopeKey) ? this.props.imageOverrides[this.state.selectedScopeKey].override_source || false : false}
                                        onClick={() => {
                                            this.props.toggleOverrideSource(this.state.selectedScopeKey)
                                        }}
                                    /></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Accordion
                        exclusive={false}
                        fluid
                        panels={this.getPanels()}
                        styled
                    />
                </Grid.Column>
            </div>
        );
    }
}
