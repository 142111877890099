import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Message, Table} from "semantic-ui-react";
import TimeMachineRundownCMSImageManagerDetailView from "./TimeMachineRundownCMSImageManagerDetailView";
import TimeMachineRundownCMSImageManagerHelpModal from "./TimeMachineRundownCMSImageManagerHelpModal";


export default function TimeMachineRundownCMSImageManagerView(props) {
    const [franchiseId, setFranchiseId] = useState("");
    const [franchiseName, setFranchiseName] = useState("");
    const [seriesId, setSeriesId] = useState("");
    const [seriesName, setSeriesName] = useState("");
    const [seasonId, setSeasonId] = useState("");
    const [seasonName, setSeasonName] = useState("");
    const [titleId, setTitleId] = useState("");
    const [titleName, setTitleName] = useState("");
    const [propEp, setPropEp] = useState("");
    const [showId, setShowId] = useState("");
    const [externalId, setExternalId] = useState("");
    const [instance, setInstance] = useState("");
    const [deletingImage, setDeletingImage] = useState(false);
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

    useEffect(function onSelectedShowUpdate() {
        if (props.selectedShow) {
            setFranchiseId(props.selectedShow.franchise_id || "");
            setFranchiseName(props.selectedShow.franchise_name || "");
            setSeriesId(props.selectedShow.series_id || "");
            setSeriesName(props.selectedShow.series_name || "");
            setSeasonId(props.selectedShow.season_id || "");
            setSeasonName(props.selectedShow.season_name || "");
            setTitleId(props.selectedShow.title_id || "");
            setTitleName(props.selectedShow.title || "");
            setPropEp(props.selectedShow.prop_ep || "");
            setShowId(props.selectedShow.show_id || "");
            setExternalId(props.selectedShow.external_id || "");
            setInstance(props.selectedShow.feed_id || "");
        }
    }, [JSON.stringify(props.selectedShow)]);

    const toggleOverrideSource = scopeToToggle => {
        console.log(`TimeMachineRundownCMSImageManagerView.toggleOverrideSource scope to toggle: ${scopeToToggle}, image overrides: `, props.imageOverrides);
        const updatedImages = {};
        const updatedOverrideSource = props.imageOverrides.hasOwnProperty(scopeToToggle) && props.imageOverrides[scopeToToggle].hasOwnProperty("override_source") ? !!!props.imageOverrides[scopeToToggle].override_source : false;
        console.log(`TimeMachineRundownCMSImageManagerView.toggleOverrideSource scope to toggle: ${scopeToToggle}, setting override_source to ${updatedOverrideSource} for image overrides: `, props.imageOverrides[scopeToToggle]);

        for (const language of props.languagesSupported) {
            for (const scope of Object.keys(props.imageOverrides)) {
                const imageOverrides = Object.assign({}, props.imageOverrides[scope].i18n[language]);
                updatedImages[scope] = {
                    override_source: scope === scopeToToggle ? !!!props.imageOverrides[scope].override_source : !!props.imageOverrides[scope].override_source,
                    i18n: {
                        [language]: {}
                    }
                };
                for (const imageId of Object.keys(imageOverrides)) {
                    updatedImages[scope].i18n[language][imageId] = imageOverrides[imageId].url;
                }
            }
        }

        console.log(`TimeMachineRundownCMSImageManagerView.toggleOverrideSource updated image overrides: `, updatedImages);


        const formattedImageOverrides = [];
        if (franchiseId && scopeToToggle.toLowerCase() === "franchise") {
            const formattedFranchiseOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.franchise.i18n)) {
                if (!formattedFranchiseOverrides.i18n.hasOwnProperty(language)) {
                    formattedFranchiseOverrides.i18n[language] = {};
                }

                for (const [key, value] of Object.entries(updatedImages.franchise.i18n[language])) {
                    if (value.length > 0) {
                        formattedFranchiseOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                franchise_id: franchiseId,
                overrides: formattedFranchiseOverrides,
                override_source: updatedOverrideSource
            });
        } else if (seriesId && scopeToToggle.toLowerCase() === "series") {
            const formattedSeriesOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.series.i18n)) {
                if (!formattedSeriesOverrides.i18n.hasOwnProperty(language)) {
                    formattedSeriesOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.series.i18n[language])) {
                    if (value.length > 0) {
                        formattedSeriesOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                series_id: seriesId,
                overrides: formattedSeriesOverrides,
                override_source: updatedOverrideSource
            });
        } else if (seasonId && scopeToToggle.toLowerCase() === "season") {
            const formattedSeasonOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.season.i18n)) {
                if (!formattedSeasonOverrides.i18n.hasOwnProperty(language)) {
                    formattedSeasonOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.season.i18n[language])) {
                    if (value.length > 0) {
                        formattedSeasonOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                season_id: seasonId,
                overrides: formattedSeasonOverrides,
                override_source: updatedOverrideSource
            });
        } else if (propEp && scopeToToggle.toLowerCase() === "prop_ep") {
            const formattedPropEpOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.prop_ep.i18n)) {
                if (!formattedPropEpOverrides.i18n.hasOwnProperty(language)) {
                    formattedPropEpOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.prop_ep.i18n[language])) {
                    if (value.length > 0) {
                        formattedPropEpOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                prop_ep: propEp,
                overrides: formattedPropEpOverrides,
                override_source: updatedOverrideSource
            });
        } else if (titleId && scopeToToggle.toLowerCase() === "title") {
            const formattedTitleOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.title.i18n)) {
                if (!formattedTitleOverrides.i18n.hasOwnProperty(language)) {
                    formattedTitleOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.title.i18n[language])) {
                    if (value.length > 0) {
                        formattedTitleOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                title_id: titleId,
                overrides: formattedTitleOverrides,
                override_source: updatedOverrideSource
            });
        } else if (externalId && scopeToToggle.toLowerCase() === "external_id") {
            const formattedExternalIdOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.external_id.i18n)) {
                if (!formattedExternalIdOverrides.i18n.hasOwnProperty(language)) {
                    formattedExternalIdOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.external_id.i18n[language])) {
                    if (value.length > 0) {
                        formattedExternalIdOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                external_id: externalId,
                overrides: formattedExternalIdOverrides,
                override_source: updatedOverrideSource
            });
        } else if (showId && scopeToToggle.toLowerCase() === "show") {
            const formattedShowOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.show.i18n)) {
                if (!formattedShowOverrides.i18n.hasOwnProperty(language)) {
                    formattedShowOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.show.i18n[language])) {
                    if (value.length > 0) {
                        formattedShowOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                show_id: showId,
                overrides: formattedShowOverrides,
                override_source: updatedOverrideSource
            });
        }

        console.log("TimeMachineRundownCMSImageManagerView.toggleOverrideSource: saving formatted image overrides: ", formattedImageOverrides, "original image overrides: ", props.imageOverrides, "toggling for scope: ", scopeToToggle);

        return props.saveImageOverrides(formattedImageOverrides).then(() => {
            props.setSuccessMessage(`Original images for the scope ${scopeToToggle} are set to${updatedOverrideSource === true ? "" : " not"} be overridden.`);
            return true;
        }).catch(error => {
            console.error(error);
            if (!props.errorMessage) {
                props.setErrorMessage("There was an error saving image overrides.");
            }
            return false;
        });
    }

    const updateImage = (scopeToUpdate, scopeId, imageIdToUpdate, languageToUpdate, url) => {
        const updatedImages = {};
        console.log("TimeMachineRundownCMSImageManagerView.updateImage image overrides: ", props.imageOverrides);

        let scopeKey;
        switch (scopeToUpdate) {
            case "title by prop ep":
                scopeKey = "prop_ep";
                break;
            case "title by title id":
                scopeKey = "title";
                break;
            case "show by external id":
                scopeKey = "external_id";
                break;
            case "show by show id":
                scopeKey = "show";
                break;
            default:
                scopeKey = scopeToUpdate;
        }

        console.log(`TimeMachineRundownCMSImageManagerView.updateImage attempting to update scope "${scopeToUpdate}" (key ${scopeKey}) ${scopeId} in language ${languageToUpdate} image id ${imageIdToUpdate} to URL ${url}`);

        for (const scope of Object.keys(props.imageOverrides)) {
            updatedImages[scope] = {
                overrides: {
                    i18n: {}
                },
                override_source: !!props.imageOverrides[scope].override_source
            };
            for (const language of Object.keys(props.imageOverrides[scope].i18n)) {
                const imageOverrides = props.imageOverrides[scope].i18n[language];
                updatedImages[scope].overrides.i18n[language] = {};
                for (const imageId of Object.keys(imageOverrides)) {
                    if (props.imageSpecs.hasOwnProperty(imageId)) {
                        if (scope === scopeKey && imageId === imageIdToUpdate && language === languageToUpdate) {
                            updatedImages[scope].overrides.i18n[language][imageId] = url;
                        } else {
                            updatedImages[scope].overrides.i18n[language][imageId] = imageOverrides[imageId].url;
                        }
                    }
                }
            }
        }

        const formattedImageOverrides = [];
        if (franchiseId && scopeKey.toLowerCase() === "franchise") {
            const formattedFranchiseOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.franchise.overrides.i18n)) {
                if (!formattedFranchiseOverrides.i18n.hasOwnProperty(language)) {
                    formattedFranchiseOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.franchise.overrides.i18n[language])) {
                    if (value.length > 0) {
                        formattedFranchiseOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                franchise_id: franchiseId,
                overrides: formattedFranchiseOverrides,
                override_source: updatedImages.franchise.override_source || false
            });
        }

        if (seriesId && scopeToUpdate.toLowerCase() === "series") {
            const formattedSeriesOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.series.overrides.i18n)) {
                if (!formattedSeriesOverrides.i18n.hasOwnProperty(language)) {
                    formattedSeriesOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.series.overrides.i18n[language])) {
                    if (value.length > 0) {
                        formattedSeriesOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                series_id: seriesId,
                overrides: formattedSeriesOverrides,
                override_source: updatedImages.series.override_source || false
            });
        }

        if (seasonId && scopeToUpdate.toLowerCase() === "season") {
            const formattedSeasonOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.season.overrides.i18n)) {
                if (!formattedSeasonOverrides.i18n.hasOwnProperty(language)) {
                    formattedSeasonOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.season.overrides.i18n[language])) {
                    if (value.length > 0) {
                        formattedSeasonOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                season_id: seasonId,
                overrides: formattedSeasonOverrides,
                override_source: updatedImages.season.override_source || false
            })
        }

        if (propEp && scopeToUpdate.toLowerCase() === "title by prop ep") {
            const formattedPropEpOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.prop_ep.overrides.i18n)) {
                if (!formattedPropEpOverrides.i18n.hasOwnProperty(language)) {
                    formattedPropEpOverrides.i18n[language] = {};
                }

                for (const [key, value] of Object.entries(updatedImages.prop_ep.overrides.i18n[language])) {
                    if (value.length > 0) {
                        formattedPropEpOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                prop_ep: propEp,
                overrides: formattedPropEpOverrides,
                override_source: updatedImages.prop_ep.override_source || false
            });
            console.log("TimeMachineRundownCMSImageManagerView.updateImage: prop_ep overrides: ", updatedImages.prop_ep, "from image overrides: ", props.imageOverrides.prop_ep, "image specs: ", props.imageSpecs, "formatted prop ep overrides: ", formattedPropEpOverrides);

        }

        if (titleId && scopeToUpdate.toLowerCase() === "title by title id") {
            const formattedTitleOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.title.overrides.i18n)) {
                if (!formattedTitleOverrides.i18n.hasOwnProperty(language)) {
                    formattedTitleOverrides.i18n[language] = {};
                }
                for (const [key, value] of Object.entries(updatedImages.title.overrides.i18n[language])) {
                    if (value.length > 0) {
                        formattedTitleOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                title_id: titleId,
                overrides: formattedTitleOverrides,
                override_source: updatedImages.title.override_source || false
            });
        }

        if (externalId && scopeToUpdate.toLowerCase() === "show by external id") {
            const formattedExternalIdOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.external_id.overrides.i18n)) {
                if (!formattedExternalIdOverrides.i18n.hasOwnProperty(language)) {
                    formattedExternalIdOverrides.i18n[language] = {};
                }
                console.log("TimeMachineRundownCMSImageManagerView.updateImage: external id overrides: ", updatedImages.external_id, "from image overrides: ", props.imageOverrides[scopeKey], "image specs: ", props.imageSpecs);
                for (const [key, value] of Object.entries(updatedImages.external_id.overrides.i18n[language])) {
                    if (value.length > 0) {
                        formattedExternalIdOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                external_id: externalId,
                overrides: formattedExternalIdOverrides,
                override_source: updatedImages.show.override_source || false
            });
        }

        if (showId && scopeToUpdate.toLowerCase() === "show by show id") {
            const formattedShowOverrides = {i18n: {}};
            for (const language of Object.keys(updatedImages.show.overrides.i18n)) {
                if (!formattedShowOverrides.i18n.hasOwnProperty(language)) {
                    formattedShowOverrides.i18n[language] = {};
                }
                console.log("TimeMachineRundownCMSImageManagerView.updateImage: show overrides: ", updatedImages.show, "from image overrides: ", props.imageOverrides[scopeToUpdate], "image specs: ", props.imageSpecs);
                for (const [key, value] of Object.entries(updatedImages.show.overrides.i18n[language])) {
                    if (value.length > 0) {
                        formattedShowOverrides.i18n[language][key] = value;
                    }
                }
            }

            formattedImageOverrides.push({
                show_id: showId,
                overrides: formattedShowOverrides,
                override_source: updatedImages.show.override_source || false
            });
        }

        console.log("TimeMachineRundownCMSImageManagerView.updateImage: saving formatted images: ", formattedImageOverrides);

        return props.saveImageOverrides(formattedImageOverrides).then(() => {
            props.setSuccessMessage(`The image ${imageIdToUpdate} for ${scopeKey} in ${languageToUpdate} with ID ${scopeId} has been updated.`);
            return true;
        }).catch(error => {
            console.error(error);
            if (!props.errorMessage) {
                props.setErrorMessage("There was an error saving image overrides.");
            }
            return false;
        });
    };

    const removeImageFromOverrides = (scope, scopeId, imageId, language) => {
        return updateImage(scope, scopeId, imageId, language, "");
    };

    const deleteImage = url => {
        setDeletingImage(true);
        props.TimeMachineDataProvider.cmsDeleteImage(url).then(response => {
            console.log("response from deleting image: ", response);
        }).catch(error => {
            console.error(error);
            props.setErrorMessage(`There was an error deleting the removed image at ${url}.`);
        }).finally(() => {
            setDeletingImage(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <TimeMachineRundownCMSImageManagerHelpModal
                isModalOpen={isHelpModalOpen}
                onOpenModal={() => setIsHelpModalOpen(true)}
                onCloseModal={() => setIsHelpModalOpen(false)}
            />
            <Grid.Row>
                <Grid.Column width={16}>
                    <Container fluid>
                        <Form widths="equal">
                            <Form.Group>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button floated="left" onClick={() => props.setEditingImages(false)}>
                                        <Icon name="arrow left"/>Go Back
                                    </Button>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button onClick={() => setIsHelpModalOpen(true)} icon floated="right"><Icon name="help"/>Help</Button>
                                </Form.Field>
                            </Form.Group>

                        </Form>
                    </Container>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Table definition>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Show</Table.HeaderCell>
                                <Table.HeaderCell>Title</Table.HeaderCell>
                                <Table.HeaderCell>Season</Table.HeaderCell>
                                <Table.HeaderCell>Series</Table.HeaderCell>
                                <Table.HeaderCell>Franchise</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign="right" color="gray" inverted>ID</Table.Cell>
                                <Table.Cell>showId: {showId}<br/><br/>externalId: {externalId}</Table.Cell>
                                <Table.Cell>{titleId}</Table.Cell>
                                <Table.Cell>{seasonId}</Table.Cell>
                                <Table.Cell>{seriesId}</Table.Cell>
                                <Table.Cell>{franchiseId}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell textAlign="right" color="gray" inverted>Name</Table.Cell>
                                <Table.Cell color="gray"></Table.Cell>
                                <Table.Cell>{titleName}</Table.Cell>
                                <Table.Cell>{seasonName}</Table.Cell>
                                <Table.Cell>{seriesName}</Table.Cell>
                                <Table.Cell>{franchiseName}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
            {
                props.savingImages ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Saving images...</Message.Content>
                    </Message> :
                deletingImage ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Deleting old image...</Message.Content>
                    </Message> :
                props.successMessage ?
                    <Message icon success>
                        <Icon name="check" />
                        <Message.Content>{props.successMessage}</Message.Content>
                    </Message> :
                props.errorMessage ?
                    <Message icon error>
                        <Icon name="warning" />
                        <Message.Content>{props.errorMessage}</Message.Content>
                    </Message> : ""
            }
            <Grid.Row>
            <TimeMachineRundownCMSImageManagerDetailView
                franchiseId={franchiseId}
                seriesId={seriesId}
                seasonId={seasonId}
                titleId={titleId}
                propEp={propEp}
                showId={showId}
                externalId={externalId}
                images={props.images}
                imageSpecs={props.imageSpecs}
                imageOverrides={props.imageOverrides}
                updateImage={updateImage}
                removeImageFromOverrides={removeImageFromOverrides}
                deleteImage={deleteImage}
                TimeMachineDataProvider={props.TimeMachineDataProvider}
                userCanEdit={props.userCanEdit}
                allPermissions={props.allPermissions}
                instance={instance}
                module={props.module}
                service={props.service}
                user={props.user}
                imageNotFoundURL={props.imageNotFoundURL}
                toggleOverrideSource={toggleOverrideSource}
                languagesSupported={props.languagesSupported}
            />
            </Grid.Row>
        </Grid>
    );
};
