import React, {useState, useEffect} from "react";
import {Confirm, Item, Table} from "semantic-ui-react";
import PropTypes from "prop-types";
import TimeMachineRundownCMSImageUploadModal from "./TimeMachineRundownCMSImageUploadModal";
import ContingentButton from "../../ContingentButton";

export default function TimeMachineRundownCMSImageEditorItem(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    }

    useEffect(() => {
        console.log("TimeMachineRundownCMSImageEditorItem: image editor item props: ", props)
    }, [JSON.stringify(props)]);


    const updateImage = (scope, scopeId, imageId, url) => {
        const urlToDelete = (" " + props.imageOverride.url).slice(1);

        console.log(`TimeMachineCMSImageEditorItem.handleDeleteImage: updating image with scope ${scope}, scope id ${scopeId}, language ${props.language}, and image id ${imageId} to have url ${url}`)
        if (props.updateImage(scope, scopeId, imageId, props.language, url)) {
            console.log(`sending url ${urlToDelete} for deletion`);
            if (urlToDelete.length > 0) {
                props.deleteImage(urlToDelete);
            }
        }
    }

    const handleDeleteImage = (scope, scopeId, imageId) => {
        const urlToDelete = (" " + props.imageOverride.url).slice(1);

        console.log(`TimeMachineCMSImageEditorItem.handleDeleteImage: removing image with scope ${scope}, scope id ${scopeId}, language ${props.language}, and image id ${imageId}`)

        if (props.removeImageFromOverrides(scope, scopeId, imageId, props.language)) {
            console.log(`sending url ${urlToDelete} for deletion`);
            props.deleteImage(urlToDelete);
        }

        setIsDeleteOpen(false);
    }

    return (
        <Item>
            <Item.Image
                size="large"
                src={props.imageOverride.url || props.imageNotFoundURL || "https://akamai.warnermediacdn.com/inflow/lower/18c8d64c-9081-4a3d-b15a-a2cdcd6ef19e.jpeg"}
                fluid
            />
            <Item.Content verticalAlign="top">
                <Item.Meta>{props.imageOverride.url ? <a href={props.imageOverride.url} target="_blank">{props.imageOverride.url}</a> : "No Image Override"}</Item.Meta>
                <Item.Description>
                    <Table celled basic fixed>
                        <Table.Body>
                            {
                                Object.keys(props.imageOverride).filter(key => !["url", "imageId", "name"].includes(key)).map(
                                    key => (
                                        <Table.Row>
                                            <Table.Cell><b>{`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</b></Table.Cell>
                                            <Table.Cell>{props.imageOverride[key]}</Table.Cell>
                                        </Table.Row>
                                    )
                                )
                            }
                        </Table.Body>
                    </Table>
                    <TimeMachineRundownCMSImageUploadModal
                        scope={props.scope}
                        scopeId={props.scopeId}
                        isModalOpen={isModalOpen}
                        onOpenModal={onOpenModal}
                        onCloseModal={onCloseModal}
                        trigger={
                            <ContingentButton
                                allPermissions={props.allPermissions}
                                module={props.module}
                                scope={props.instance}
                                service={props.service}
                                user={props.user}
                                hideIfNotPermitted={true}
                            >Update Image</ContingentButton>
                        }
                        imageOverride={props.imageOverride}
                        imageId={props.imageId}
                        TimeMachineDataProvider={props.TimeMachineDataProvider}
                        updateImage={updateImage}
                    />
                    <ContingentButton
                        disabled={!props.imageOverride.url}
                        onClick={() => setIsDeleteOpen(true)}
                        floated="right"
                        allPermissions={props.allPermissions}
                        module={props.module}
                        scope={props.instance}
                        service={props.service}
                        user={props.user}
                        hideIfNotPermitted={true}
                    >Remove Image</ContingentButton>
                    <Confirm
                        open={isDeleteOpen}
                        onCancel={() => setIsDeleteOpen(false)}
                        onConfirm={() => {
                            handleDeleteImage(props.scope.toLowerCase(), props.scopeId, props.imageId);
                        }}
                        content="Are you sure you want to remove the image?"
                    />
                </Item.Description>
            </Item.Content>
        </Item>
    );
};

TimeMachineRundownCMSImageEditorItem.propTypes = {
    imageOverride: PropTypes.object.isRequired,
    module: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    allPermissions: PropTypes.array.isRequired
}